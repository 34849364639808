package guitar.assist.model

val triad = arrayOf(1, 3, 5)
val tetrad = arrayOf(1, 3, 5, 7)

fun Note.progression(progression: Array<Int>, degrees: Array<Int>, scale: Scale): List<Chord> {
    val degreeAsIntArray = degrees.toIntArray()
    return scale.of(this, *progression.toIntArray())
        .mapIndexed { idx, key ->
            val newScale = scale.mode(progression[idx] - 1)
            val notes = newScale.of(key, *degreeAsIntArray)
            Chord(notes.first(), notes, newScale, "||$notes||")
        }.map { chord ->
            chordBuilders
                .firstOrNull { chordBuilder ->
                    val possibleChord = chordBuilder(chord.notes.first())
                    possibleChord.notes == chord.notes
                }
                ?.invoke(chord.notes.first())
                ?: chord
        }
}