package guitar.assist.model

import kotlinx.serialization.Serializable

@Serializable
data class Project(
    val chordSequences: List<ChordSequence>
) {
    companion object {
        fun empty() = Project(emptyList())
    }
}

@Serializable
data class ChordSequence(
    val chords: List<Pair<Chord, ChordShape?>>,
) {
    operator fun plus(chord: Chord): ChordSequence {
        return ChordSequence(ArrayList(chords).plus(chord to null))
    }

    operator fun plus(chordAndShape: Pair<Chord, ChordShape>): ChordSequence {
        return ChordSequence(ArrayList(chords).plus(chordAndShape))
    }

    fun replace(idx: Int, chordAndShape: Pair<Chord, ChordShape>): ChordSequence {
        return ChordSequence(chords.mapIndexed { i, p ->
            if (idx == i) {
                chordAndShape
            } else {
                p
            }
        })
    }

    fun remove(idx: Int): ChordSequence {
        return ChordSequence(chords.mapIndexedNotNull { i, p ->
            if (idx == i) {
                null
            } else {
                p
            }
        })
    }

    fun swap(idx: Int, newIdx: Int): ChordSequence {
        val a = chords[idx]
        val b = chords[newIdx]
        return ChordSequence(chords.mapIndexedNotNull { i, p ->
            if (idx == i) {
                b
            } else if (newIdx == i) {
                a
            } else {
                p
            }
        })
    }
}