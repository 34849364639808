package guitar.assist.model

import kotlinx.serialization.Serializable

@Serializable
data class Chord(
    val rootNote: Note,
    val notes: List<Note>,
    val scale: Scale,
    val shapesKey: String
) {
    override fun toString(): String = rootNote.symbol + shapesKey

    fun scaleNotes(): List<Note> = scale.of(rootNote, 1, 2, 3, 4, 5, 6, 7, 8)

    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class.js != other::class.js) return false

        other as Chord

        if (rootNote != other.rootNote) return false
        if (notes != other.notes) return false

        return true
    }

    override fun hashCode(): Int {
        var result = rootNote.hashCode()
        result = 31 * result + notes.hashCode()
        return result
    }

}

val chordBuilders: List<(Note) -> Chord> = listOf(
    { n -> n.maj },
    { n -> n.m },
    { n -> n.m7 },
    { n -> n.m7b5 },
    { n -> n.dim },
    { n -> n.dim7 },
    { n -> n.maj7 },
    { n -> n.maj9 },
    { n -> n.maj11 },
    { n -> n.maj13 },
    { n -> n.maj7s11 },
    { n -> n.maj9s11 },
    { n -> n.sus2 },
    { n -> n.sus4 },
    { n -> n.m6 },
    { n -> n.m9 },
    { n -> n.m11 },
    { n -> n.dom7 },
    { n -> n.dom9 },
    { n -> n.mM7 },
    { n -> n.maj7s5 },
    { n -> n.M6 },
)

val majorNinth = "maj9" // maj7/9, M9, :delta:9

val shapes = mapOf(
    "maj" to emptyList(),
    "sus2" to emptyList(),
    "sus4" to emptyList(),
    "m" to emptyList(),
    "m7" to MinorSeventh.drop2 + MinorSeventh.drop3 + MinorSeventh.drop24,
    "m7b5" to HalfDiminished.drop2 + HalfDiminished.drop3 + HalfDiminished.drop24,
    "m6" to MinorSixth.drop2 + MinorSixth.drop3 + MinorSixth.drop24,
    "dim" to emptyList(),
    "dim7" to DiminishedSeventh.drop2 + DiminishedSeventh.drop3 + DiminishedSeventh.drop24,
    "maj7" to MajorSeventh.drop2 + MajorSeventh.drop3 + MajorSeventh.drop24,
    "6" to MajorSixth.drop2 + MajorSixth.drop3 + MajorSixth.drop24,
    "7" to DominantSeventh.drop2 + DominantSeventh.drop3 + DominantSeventh.drop24,
    "9" to emptyList(),
    majorNinth to MajorNinth.omit5 + MajorNinth.rootlessDerivedFromDrop2Maj7 + MajorNinth.rootlessDerivedFromDrop3Maj7 + MajorNinth.rootlessDerivedFromDrop24Maj7,
    "m7/9" to emptyList(),
    "maj11" to emptyList(),
    "m11" to emptyList(),
    "maj13" to emptyList(),
    "maj7#11" to emptyList(),
    "maj9#11" to emptyList(),
    "mM7" to MinorMajorSeventh.drop2 + MinorMajorSeventh.drop3 + MinorMajorSeventh.drop24,
    "maj7#5" to AugmentMajorSeventh.drop2 + AugmentMajorSeventh.drop3 + AugmentMajorSeventh.drop24,
)

fun Chord.shapes(): List<ChordShape> = shapes[this.shapesKey] ?: emptyList()

val Note.maj
    get(): Chord = Chord(this, Ionian.of(this, 1, 3, 5), Ionian, "maj")
val Note.sus2
    get(): Chord = Chord(this, Ionian.of(this, 1, 2, 5), Ionian, "sus2")
val Note.sus4
    get(): Chord = Chord(this, Ionian.of(this, 1, 4, 5), Ionian, "sus4")
val Note.m
    get(): Chord = Chord(this, Dorian.of(this, 1, 3, 5), Dorian, "m")
val Note.m7
    get(): Chord = Chord(this, Dorian.of(this, 1, 3, 5, 7), Dorian, "m7")
val Note.m7b5
    get(): Chord = Chord(this, Locrian.of(this, 1, 3, 5, 7), Locrian, "m7b5")
val Note.m6
    get(): Chord = Chord(this, Dorian.of(this, 1, 3, 5, 6), Dorian, "m6")
val Note.dim
    get(): Chord = Chord(this, Locrian.of(this, 1, 3, 5), Locrian, "dim")
val Note.dim7
    get(): Chord = Chord(
        this,
        Locrian.of(this, 1, 3, 5, 7).mapIndexed { idx, n -> if (idx == 3) n.subSemitone() else n },
        Locrian,
        "dim7"
    )
val Note.maj7
    get(): Chord = Chord(this, Ionian.of(this, 1, 3, 5, 7), Ionian, "maj7")
val Note.M6
    get(): Chord = Chord(this, Mixolydian.of(this, 1, 3, 5, 6), Mixolydian, "6")
val Note.dom7
    get(): Chord = Chord(this, Mixolydian.of(this, 1, 3, 5, 7), Mixolydian, "7")
val Note.dom9
    get(): Chord = Chord(this, Mixolydian.of(this, 1, 3, 5, 7, 9), Mixolydian, "9")
val Note.maj9
    get(): Chord = Chord(this, Ionian.of(this, 1, 3, 5, 7, 9), Ionian, majorNinth)
val Note.m9
    get(): Chord = Chord(this, Aeolian.of(this, 1, 3, 5, 7, 9), Aeolian, "m7/9")
val Note.maj11
    get(): Chord = Chord(this, Ionian.of(this, 1, 3, 5, 7, 9, 11), Ionian, "maj11")
val Note.m11
    get(): Chord = Chord(this, Aeolian.of(this, 1, 3, 5, 7, 9, 11), Aeolian, "m11")
val Note.maj13
    get(): Chord = Chord(this, Ionian.of(this, 1, 3, 5, 7, 9, 11, 13), Ionian, "maj13")

val Note.maj7s11
    get(): Chord = Chord(this, Lydian.of(this, 1, 3, 5, 7, 11), Lydian, "maj7#11")
val Note.maj9s11
    get(): Chord = Chord(this, Lydian.of(this, 1, 3, 5, 7, 9, 11), Lydian, "maj9#11")

val Note.mM7
    get(): Chord = Chord(this, HarmonicMinor.of(this, 1, 3, 5, 7), HarmonicMinor, "mM7")
val Note.maj7s5
    get(): Chord = Chord(this, LydianAugmented.of(this, 1, 3, 5, 7), LydianAugmented, "maj7#5")