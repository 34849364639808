package guitar.assist.model

import guitar.assist.model.GuitarTuning.Companion.standard
import kotlinx.serialization.Serializable


object MajorSeventh {
    val drop2 = listOf(
        ChordShape.of(standard, o(1, 6, 0), o(3, 3, 1), o(5, 5, 2), o(7, 4, 1)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 6, 2), o(5, 3, 2), o(7, 5, 4)),
        ChordShape.of(standard, o(1, 5, 0), o(3, 4, -1), o(5, 6, 0), o(7, 3, 1)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 5, 2), o(5, 4, 0), o(7, 6, 2)),
        ChordShape.of(standard, o(1, 5, 0), o(3, 2, 2), o(5, 4, 2), o(7, 3, 1)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 5, 2), o(5, 2, 3), o(7, 4, 4)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 3, -1), o(5, 5, 0), o(7, 2, 2)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 4, 1), o(5, 3, -1), o(7, 5, 1)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 1, 2), o(5, 3, 2), o(7, 2, 2)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 4, 1), o(5, 1, 2), o(7, 3, 3)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 2, 0), o(5, 4, 0), o(7, 1, 2)),
        ChordShape.of(standard, o(1, 1, 0), o(3, 3, 1), o(5, 2, 0), o(7, 4, 1))
    )
    val drop3 = listOf(
        ChordShape.of(standard, o(1, 6, 0), o(3, 3, 1), o(5, 2, 0), o(7, 4, 1)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 6, 2), o(5, 3, 2), o(7, 2, 2)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 4, 1), o(5, 6, 2), o(7, 3, 3)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 2, 0), o(5, 4, 0), o(7, 6, 2)),
        ChordShape.of(standard, o(1, 5, 0), o(3, 2, 2), o(5, 1, 0), o(7, 3, 1)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 5, 2), o(5, 2, 3), o(7, 1, 2)),
        ChordShape.of(standard, o(1, 1, 0), o(3, 3, 1), o(5, 5, 2), o(7, 2, 4)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 1, -1), o(5, 3, -1), o(7, 5, 1))
    )
    val drop24 = listOf(
        ChordShape.of(standard, o(1, 6, 0), o(3, 3, 1), o(5, 5, 2), o(7, 2, 4)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 6, -1), o(5, 3, -1), o(7, 5, 1)),
        ChordShape.of(standard, o(1, 5, 0), o(3, 2, 2), o(5, 6, 0), o(7, 3, 1)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 5, 2), o(5, 2, 3), o(7, 6, 2)),
        ChordShape.of(standard, o(1, 5, 0), o(3, 2, 2), o(5, 4, 2), o(7, 1, 4)),
        ChordShape.of(standard, o(1, 1, 0), o(3, 5, -1), o(5, 2, 0), o(7, 4, 1)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 1, 2), o(5, 5, 0), o(7, 2, 2)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 4, 1), o(5, 1, 2), o(7, 5, 1))
    )
}

object DominantSeventh {
    val drop2 = listOf(
        ChordShape.of(standard, o(1, 6, 0), o(3, 3, 1), o(5, 5, 2), o(7, 4, 0)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 6, 2), o(5, 3, 2), o(7, 5, 3)),
        ChordShape.of(standard, o(1, 5, 0), o(3, 4, -1), o(5, 6, 0), o(7, 3, 0)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 5, 2), o(5, 4, 0), o(7, 6, 1)),
        ChordShape.of(standard, o(1, 5, 0), o(3, 2, 2), o(5, 4, 2), o(7, 3, 0)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 5, 2), o(5, 2, 3), o(7, 4, 3)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 3, -1), o(5, 5, 0), o(7, 2, 1)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 4, 1), o(5, 3, -1), o(7, 5, 0)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 1, 2), o(5, 3, 2), o(7, 2, 1)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 4, 1), o(5, 1, 2), o(7, 3, 2)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 2, 0), o(5, 4, 0), o(7, 1, 1)),
        ChordShape.of(standard, o(1, 1, 0), o(3, 3, 1), o(5, 2, 0), o(7, 4, 0))
    )
    val drop3 = listOf(
        ChordShape.of(standard, o(1, 6, 0), o(3, 3, 1), o(5, 2, 0), o(7, 4, 0)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 6, 2), o(5, 3, 2), o(7, 2, 1)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 4, 1), o(5, 6, 2), o(7, 3, 2)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 2, 0), o(5, 4, 0), o(7, 6, 1)),
        ChordShape.of(standard, o(1, 5, 0), o(3, 2, 2), o(5, 1, 0), o(7, 3, 0)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 5, 2), o(5, 2, 3), o(7, 1, 1)),
        ChordShape.of(standard, o(1, 1, 0), o(3, 3, 1), o(5, 5, 2), o(7, 2, 3)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 1, -1), o(5, 3, -1), o(7, 5, 0))
    )
    val drop24 = listOf(
        ChordShape.of(standard, o(1, 6, 0), o(3, 3, 1), o(5, 4, 2), o(7, 2, 3)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 6, -1), o(5, 3, -1), o(7, 5, 0)),
        ChordShape.of(standard, o(1, 5, 0), o(3, 2, 2), o(5, 6, 0), o(7, 3, 0)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 5, 2), o(5, 2, 3), o(7, 6, 1)),
        ChordShape.of(standard, o(1, 5, 0), o(3, 2, 2), o(5, 4, 2), o(7, 1, 3)),
        ChordShape.of(standard, o(1, 1, 0), o(3, 5, -1), o(5, 2, 0), o(7, 4, 0)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 1, 2), o(5, 5, 0), o(7, 2, 1)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 4, 1), o(5, 1, 2), o(7, 5, 0))
    )
}

object MinorSeventh {
    val drop2 = listOf(
        ChordShape.of(standard, o(1, 6, 0), o(3, 3, 0), o(5, 5, 2), o(7, 4, 0)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 6, 1), o(5, 3, 2), o(7, 5, 3)),
        ChordShape.of(standard, o(1, 5, 0), o(3, 4, -2), o(5, 6, 0), o(7, 3, 0)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 5, 1), o(5, 4, 0), o(7, 6, 1)),
        ChordShape.of(standard, o(1, 5, 0), o(3, 2, 1), o(5, 4, 2), o(7, 3, 0)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 5, 1), o(5, 2, 3), o(7, 4, 3)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 3, -2), o(5, 5, 0), o(7, 2, 1)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 4, 0), o(5, 3, -1), o(7, 5, 0)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 1, 1), o(5, 3, 2), o(7, 2, 1)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 4, 0), o(5, 1, 2), o(7, 3, 2)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 2, -1), o(5, 4, 0), o(7, 1, 1)),
        ChordShape.of(standard, o(1, 1, 0), o(3, 3, 0), o(5, 2, 0), o(7, 4, 0))
    )
    val drop3 = listOf(
        ChordShape.of(standard, o(1, 6, 0), o(3, 3, 0), o(5, 2, 0), o(7, 4, 0)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 6, 1), o(5, 3, 2), o(7, 2, 1)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 4, 0), o(5, 6, 2), o(7, 3, 2)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 2, -1), o(5, 4, 0), o(7, 6, 1)),
        ChordShape.of(standard, o(1, 5, 0), o(3, 2, 1), o(5, 1, 0), o(7, 3, 0)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 5, 1), o(5, 2, 3), o(7, 1, 1)),
        ChordShape.of(standard, o(1, 1, 0), o(3, 3, 0), o(5, 5, 2), o(7, 2, 3)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 1, -2), o(5, 3, -1), o(7, 5, 0)),
    )
    val drop24 = listOf(
        ChordShape.of(standard, o(1, 6, 0), o(3, 3, 0), o(5, 5, 2), o(7, 2, 3)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 6, -2), o(5, 3, -1), o(7, 5, 0)),
        ChordShape.of(standard, o(1, 5, 0), o(3, 2, 1), o(5, 6, 0), o(7, 3, 0)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 5, 1), o(5, 2, 3), o(7, 6, 1)),
        ChordShape.of(standard, o(1, 5, 0), o(3, 2, 1), o(5, 4, 2), o(7, 1, 3)),
        ChordShape.of(standard, o(1, 1, 0), o(3, 5, -2), o(5, 2, 0), o(7, 4, 0)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 1, 1), o(5, 5, 0), o(7, 2, 1)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 4, 0), o(5, 1, 2), o(7, 5, 0))
    )
}

object HalfDiminished {
    val drop2 = listOf(
        ChordShape.of(standard, o(1, 6, 0), o(3, 3, 0), o(5, 5, 1), o(7, 4, 0)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 6, 1), o(5, 3, 1), o(7, 5, 3)),
        ChordShape.of(standard, o(1, 5, 0), o(3, 4, -2), o(5, 6, -1), o(7, 3, 0)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 5, 1), o(5, 4, -1), o(7, 6, 1)),
        ChordShape.of(standard, o(1, 5, 0), o(3, 2, 1), o(5, 4, 1), o(7, 3, 0)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 5, 1), o(5, 2, 2), o(7, 4, 3)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 3, -2), o(5, 5, -1), o(7, 2, 1)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 4, 0), o(5, 3, -2), o(7, 5, 0)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 1, 1), o(5, 3, 1), o(7, 2, 1)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 4, 0), o(5, 1, 1), o(7, 3, 2)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 2, -1), o(5, 4, -1), o(7, 1, 1)),
        ChordShape.of(standard, o(1, 1, 0), o(3, 3, 0), o(5, 2, -1), o(7, 4, 0))
    )
    val drop3 = listOf(
        ChordShape.of(standard, o(1, 6, 0), o(3, 3, 0), o(5, 2, -1), o(7, 4, 0)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 6, 1), o(5, 3, 1), o(7, 2, 1)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 4, 0), o(5, 6, 1), o(7, 3, 2)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 2, -1), o(5, 4, -1), o(7, 6, 1)),
        ChordShape.of(standard, o(1, 5, 0), o(3, 2, 1), o(5, 1, -1), o(7, 3, 0)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 5, 1), o(5, 2, 2), o(7, 1, 1)),
        ChordShape.of(standard, o(1, 1, 0), o(3, 3, 0), o(5, 5, 1), o(7, 2, 3)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 1, -2), o(5, 3, -2), o(7, 5, 0))
    )
    val drop24 = listOf(
        ChordShape.of(standard, o(1, 6, 0), o(3, 3, 0), o(5, 5, 1), o(7, 2, 3)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 6, -2), o(5, 3, -2), o(7, 5, 0)),
        ChordShape.of(standard, o(1, 5, 0), o(3, 2, 1), o(5, 6, -1), o(7, 3, 0)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 5, 1), o(5, 2, 2), o(7, 6, 1)),
        ChordShape.of(standard, o(1, 5, 0), o(3, 2, 1), o(5, 4, 1), o(7, 1, 3)),
        ChordShape.of(standard, o(1, 1, 0), o(3, 5, -2), o(5, 2, -1), o(7, 4, 0)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 1, 1), o(5, 5, -1), o(7, 2, 1)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 4, 0), o(5, 1, 1), o(7, 5, 0))
    )
}

object DiminishedSeventh {
    val drop2 = listOf(
        ChordShape.of(standard, o(1, 6, 0), o(3, 3, 0), o(5, 5, 1), o(7, 4, -1)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 6, 1), o(5, 3, 1), o(7, 5, 2)),
        ChordShape.of(standard, o(1, 5, 0), o(3, 4, -2), o(5, 6, -1), o(7, 3, -1)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 5, 1), o(5, 4, -1), o(7, 6, 0)),
        ChordShape.of(standard, o(1, 5, 0), o(3, 2, 1), o(5, 4, 1), o(7, 3, -1)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 5, 1), o(5, 2, 2), o(7, 4, 2)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 3, -2), o(5, 5, -1), o(7, 2, 0)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 4, 0), o(5, 3, -2), o(7, 5, -1)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 1, 1), o(5, 3, 1), o(7, 2, 0)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 4, 0), o(5, 1, 1), o(7, 3, 1)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 2, -1), o(5, 4, -1), o(7, 1, 0)),
        ChordShape.of(standard, o(1, 1, 0), o(3, 3, 0), o(5, 2, -1), o(7, 4, -1))
    )
    val drop3 = listOf(
        ChordShape.of(standard, o(1, 6, 0), o(3, 3, 0), o(5, 2, -1), o(7, 4, -1)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 6, 1), o(5, 3, 1), o(7, 2, 0)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 4, 0), o(5, 6, 1), o(7, 3, 1)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 2, -1), o(5, 4, -1), o(7, 6, 0)),
        ChordShape.of(standard, o(1, 5, 0), o(3, 2, 1), o(5, 1, -1), o(7, 3, -1)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 5, 1), o(5, 2, 2), o(7, 1, 0)),
        ChordShape.of(standard, o(1, 1, 0), o(3, 3, 0), o(5, 5, 1), o(7, 2, 2)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 1, -2), o(5, 3, -2), o(7, 5, -1))
    )
    val drop24 = listOf(
        ChordShape.of(standard, o(1, 6, 0), o(3, 3, 0), o(5, 5, 1), o(7, 2, 2)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 6, -2), o(5, 3, -2), o(7, 5, -1)),
        ChordShape.of(standard, o(1, 5, 0), o(3, 2, 1), o(5, 6, -1), o(7, 3, -1)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 5, 1), o(5, 2, 2), o(7, 6, 0)),
        ChordShape.of(standard, o(1, 5, 0), o(3, 2, 1), o(5, 4, 1), o(7, 1, 2)),
        ChordShape.of(standard, o(1, 1, 0), o(3, 5, -2), o(5, 2, -1), o(7, 4, -1)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 1, 1), o(5, 5, -1), o(7, 2, 0)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 4, 0), o(5, 1, 1), o(7, 5, -1))
    )
}

object MinorMajorSeventh {
    val drop2 = listOf(
        ChordShape.of(standard, o(1, 6, 0), o(3, 3, 0), o(5, 5, 2), o(7, 4, 1)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 6, 1), o(5, 3, 2), o(7, 5, 4)),
        ChordShape.of(standard, o(1, 5, 0), o(3, 4, -2), o(5, 6, 0), o(7, 3, 1)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 5, 1), o(5, 4, 0), o(7, 6, 2)),
        ChordShape.of(standard, o(1, 5, 0), o(3, 2, 1), o(5, 4, 2), o(7, 3, 1)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 5, 1), o(5, 2, 3), o(7, 4, 4)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 3, -2), o(5, 5, 0), o(7, 2, 2)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 4, 0), o(5, 3, -1), o(7, 5, 1)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 1, 1), o(5, 3, 2), o(7, 2, 2)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 4, 0), o(5, 1, 2), o(7, 3, 3)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 2, -1), o(5, 4, 0), o(7, 1, 2)),
        ChordShape.of(standard, o(1, 1, 0), o(3, 3, 0), o(5, 2, 0), o(7, 4, 1))
    )
    val drop3 = listOf(
        ChordShape.of(standard, o(1, 6, 0), o(3, 3, 0), o(5, 2, 0), o(7, 4, 1)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 6, 1), o(5, 3, 2), o(7, 2, 2)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 4, 0), o(5, 6, 2), o(7, 3, 3)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 2, -1), o(5, 4, 0), o(7, 6, 2)),
        ChordShape.of(standard, o(1, 5, 0), o(3, 2, 1), o(5, 1, 0), o(7, 3, 1)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 5, 1), o(5, 2, 3), o(7, 1, 2)),
        ChordShape.of(standard, o(1, 1, 0), o(3, 3, 0), o(5, 5, 2), o(7, 2, 4)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 1, -2), o(5, 3, -1), o(7, 5, 1))
    )
    val drop24 = listOf(
        ChordShape.of(standard, o(1, 6, 0), o(3, 3, 0), o(5, 5, 2), o(7, 2, 4)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 6, -2), o(5, 3, -1), o(7, 5, 1)),
        ChordShape.of(standard, o(1, 5, 0), o(3, 2, 1), o(5, 6, 0), o(7, 3, 1)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 5, 1), o(5, 2, 3), o(7, 6, 2)),
        ChordShape.of(standard, o(1, 5, 0), o(3, 2, 1), o(5, 4, 2), o(7, 1, 4)),
        ChordShape.of(standard, o(1, 1, 0), o(3, 5, -2), o(5, 2, 0), o(7, 4, 1)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 1, 1), o(5, 5, 0), o(7, 2, 2)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 4, 0), o(5, 1, 2), o(7, 5, 1))
    )
}

// AugmentedSeventh 7#5
// DominantSeventhFlatFive 7#11
// DominantSeventhSuspendedForth 7sus4
// DominantSeventhSuspendedSecond 7sus2

object AugmentMajorSeventh {
    val drop2 = listOf(
        ChordShape.of(standard, o(1, 6, 0), o(3, 3, 1), o(5, 5, 3), o(7, 4, 1)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 6, 2), o(5, 3, 3), o(7, 5, 4)),
        ChordShape.of(standard, o(1, 5, 0), o(3, 4, -1), o(5, 6, 1), o(7, 3, 1)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 5, 2), o(5, 4, 1), o(7, 6, 2)),
        ChordShape.of(standard, o(1, 5, 0), o(3, 2, 2), o(5, 4, 3), o(7, 3, 1)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 5, 2), o(5, 2, 4), o(7, 4, 4)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 3, -1), o(5, 5, 1), o(7, 2, 2)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 4, 1), o(5, 3, 0), o(7, 5, 1)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 1, 2), o(5, 3, 3), o(7, 2, 2)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 4, 1), o(5, 1, 3), o(7, 3, 3)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 2, 0), o(5, 4, 1), o(7, 1, 2)),
        ChordShape.of(standard, o(1, 1, 0), o(3, 3, 1), o(5, 2, 1), o(7, 4, 1))
    )
    val drop3 = listOf(
        ChordShape.of(standard, o(1, 6, 0), o(3, 3, 1), o(5, 2, 1), o(7, 4, 1)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 6, 2), o(5, 3, 3), o(7, 2, 2)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 4, 1), o(5, 6, 3), o(7, 3, 3)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 2, 0), o(5, 4, 1), o(7, 6, 2)),
        ChordShape.of(standard, o(1, 5, 0), o(3, 2, 2), o(5, 1, 1), o(7, 3, 1)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 5, 2), o(5, 2, 4), o(7, 1, 2)),
        ChordShape.of(standard, o(1, 1, 0), o(3, 3, 1), o(5, 5, 3), o(7, 2, 4)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 1, -1), o(5, 3, 0), o(7, 5, 1))
    )
    val drop24 = listOf(
        ChordShape.of(standard, o(1, 6, 0), o(3, 3, 1), o(5, 5, 3), o(7, 2, 4)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 6, -1), o(5, 3, 0), o(7, 5, 1)),
        ChordShape.of(standard, o(1, 5, 0), o(3, 2, 2), o(5, 6, 1), o(7, 3, 1)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 5, 2), o(5, 2, 4), o(7, 6, 2)),
        ChordShape.of(standard, o(1, 5, 0), o(3, 2, 2), o(5, 4, 3), o(7, 1, 4)),
        ChordShape.of(standard, o(1, 1, 0), o(3, 5, -1), o(5, 2, 1), o(7, 4, 1)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 1, 2), o(5, 5, 1), o(7, 2, 2)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 4, 1), o(5, 1, 3), o(7, 5, 1))
    )
}

object MajorSixth {
    val drop2 = listOf(
        ChordShape.of(standard, o(1, 6, 0), o(3, 3, 1), o(5, 5, 2), o(6, 4, -1)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 6, 2), o(5, 3, 2), o(6, 5, 2)),
        ChordShape.of(standard, o(1, 5, 0), o(3, 4, -1), o(5, 6, 0), o(6, 3, -1)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 5, 2), o(5, 4, 0), o(6, 6, 0)),
        ChordShape.of(standard, o(1, 5, 0), o(3, 2, 2), o(5, 4, 2), o(6, 3, -1)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 5, 2), o(5, 2, 3), o(6, 4, 2)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 3, -1), o(5, 5, 0), o(6, 2, 0)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 4, 1), o(5, 3, -1), o(6, 5, -1)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 1, 2), o(5, 3, 2), o(6, 2, 0)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 4, 1), o(5, 1, 2), o(6, 3, 1)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 2, 0), o(5, 4, 0), o(6, 1, 0)),
        ChordShape.of(standard, o(1, 1, 0), o(3, 3, 1), o(5, 2, 0), o(6, 4, -1))
    )
    val drop3 = listOf(
        ChordShape.of(standard, o(1, 6, 0), o(3, 3, 1), o(5, 2, 0), o(6, 4, -1)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 6, 2), o(5, 3, 2), o(6, 2, 0)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 4, 1), o(5, 6, 2), o(6, 3, 1)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 2, 0), o(5, 4, 0), o(6, 6, 0)),
        ChordShape.of(standard, o(1, 5, 0), o(3, 2, 2), o(5, 1, 0), o(6, 3, -1)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 5, 2), o(5, 2, 3), o(6, 1, 0)),
        ChordShape.of(standard, o(1, 1, 0), o(3, 3, 1), o(5, 5, 2), o(6, 2, 2)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 1, -1), o(5, 3, -1), o(6, 5, -1))
    )
    val drop24 = listOf(
        ChordShape.of(standard, o(1, 6, 0), o(3, 3, 1), o(5, 5, 2), o(6, 2, 2)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 6, -1), o(5, 3, -1), o(6, 5, -1)),
        ChordShape.of(standard, o(1, 5, 0), o(3, 2, 2), o(5, 6, 0), o(6, 3, -1)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 5, 2), o(5, 2, 3), o(6, 6, 0)),
        ChordShape.of(standard, o(1, 5, 0), o(3, 2, 2), o(5, 4, 2), o(6, 1, 2)),
        ChordShape.of(standard, o(1, 1, 0), o(3, 5, -1), o(5, 2, 0), o(6, 4, -1)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 1, 2), o(5, 5, 0), o(6, 2, 0)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 4, 1), o(5, 1, 2), o(6, 5, -1))
    )
}

object MinorSixth {
    val drop2 = listOf(
        ChordShape.of(standard, o(1, 6, 0), o(3, 3, 0), o(5, 5, 2), o(6, 4, -1)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 6, 1), o(5, 3, 2), o(6, 5, 2)),
        ChordShape.of(standard, o(1, 5, 0), o(3, 4, -2), o(5, 6, 0), o(6, 3, -1)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 5, 1), o(5, 4, 0), o(6, 6, 0)),
        ChordShape.of(standard, o(1, 5, 0), o(3, 2, 1), o(5, 4, 2), o(6, 3, -1)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 5, 1), o(5, 2, 3), o(6, 4, 2)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 3, -2), o(5, 5, 0), o(6, 2, 0)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 4, 0), o(5, 3, -1), o(6, 5, -1)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 1, 1), o(5, 3, 2), o(6, 2, 0)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 4, 0), o(5, 1, 2), o(6, 3, 1)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 2, -1), o(5, 4, 0), o(6, 1, 0)),
        ChordShape.of(standard, o(1, 1, 0), o(3, 3, 0), o(5, 2, 0), o(6, 4, -1)),
    )
    val drop3 = listOf(
        ChordShape.of(standard, o(1, 6, 0), o(3, 3, 0), o(5, 2, 0), o(6, 4, -1)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 6, 1), o(5, 3, 2), o(6, 2, 0)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 4, 0), o(5, 6, 2), o(6, 3, 1)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 2, -1), o(5, 4, 0), o(6, 6, 0)),
        ChordShape.of(standard, o(1, 5, 0), o(3, 2, 1), o(5, 1, 0), o(6, 3, -1)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 5, 1), o(5, 2, 3), o(6, 1, 0)),
        ChordShape.of(standard, o(1, 1, 0), o(3, 3, 0), o(5, 5, 2), o(6, 2, 2)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 1, -2), o(5, 3, -1), o(6, 5, -1))
    )
    val drop24 = listOf(
        ChordShape.of(standard, o(1, 6, 0), o(3, 3, 0), o(5, 5, 2), o(6, 2, 2)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 6, -2), o(5, 3, -1), o(6, 5, -1)),
        ChordShape.of(standard, o(1, 5, 0), o(3, 2, 1), o(5, 6, 0), o(6, 3, -1)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 5, 1), o(5, 2, 3), o(6, 6, 0)),
        ChordShape.of(standard, o(1, 5, 0), o(3, 2, 1), o(5, 4, 2), o(6, 1, 2)),
        ChordShape.of(standard, o(1, 1, 0), o(3, 5, -2), o(5, 2, 0), o(6, 4, -1)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 1, 1), o(5, 5, 0), o(6, 2, 0)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 4, 0), o(5, 1, 2), o(6, 5, -1))
    )
}

object MajorNinth {
    val omit5 = listOf(
        ChordShape.of(standard, o(1, 6, 0), o(3, 5, -1), o(7, 4, 1), o(9, 3, -1)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 3, -1), o(7, 2, 2), o(9, 6, 0)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 6, -1), o(7, 5, 1), o(9, 4, -1)),
        ChordShape.of(standard, o(1, 5, 0), o(3, 4, -1), o(7, 3, 1), o(9, 2, 0)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 2, 0), o(7, 6, 2), o(9, 5, 0)),
        ChordShape.of(standard, o(1, 1, 0), o(3, 5, -1), o(7, 4, 1), o(9, 3, -1)),
        ChordShape.of(standard, o(1, 4, 0), o(3, 3, -1), o(7, 2, 2), o(9, 1, 0)),
        ChordShape.of(standard, o(1, 2, 0), o(3, 1, -1), o(7, 5, 1), o(9, 4, -1)),
        ChordShape.of(standard, o(1, 3, 0), o(3, 2, 0), o(7, 1, 2), o(9, 5, 0)),
    )
    val rootlessDerivedFromDrop2Maj7 = listOf(
        ChordShape.of(standard, x(1, 6, 0), o(3, 3, 1), o(5, 5, 2), o(7, 4, 1), o(9, 6, 2)),
        ChordShape.of(standard, x(1, 4, 0), o(3, 6, 2), o(5, 3, 2), o(7, 5, 4), o(9, 4, 2)),
        ChordShape.of(standard, x(1, 2, 0), o(3, 4, 1), o(5, 6, 2), o(7, 3, 3), o(9, 5, 4)),
        ChordShape.of(standard, x(1, 1, 0), o(3, 5, -1), o(5, 4, -3), o(7, 6, -1), o(9, 3, -1)),
        ChordShape.of(standard, x(1, 5, 0), o(3, 2, 2), o(5, 4, 2), o(7, 3, 1), o(9, 5, 2)),
        ChordShape.of(standard, x(1, 1, 0), o(3, 5, -1), o(5, 2, 0), o(7, 4, 1), o(9, 3, -1)),
        ChordShape.of(standard, x(1, 4, 0), o(3, 3, -1), o(5, 5, 0), o(7, 2, 2), o(9, 4, 2)),
        ChordShape.of(standard, x(1, 2, 0), o(3, 4, 1), o(5, 3, -1), o(7, 5, 1), o(9, 2, 2)),
        ChordShape.of(standard, x(1, 4, 0), o(3, 1, 2), o(5, 3, 2), o(7, 2, 2), o(9, 4, 2)),
        ChordShape.of(standard, x(1, 5, 0), o(3, 4, -1), o(5, 1, 0), o(7, 3, 1), o(9, 2, 0)),
        ChordShape.of(standard, x(1, 3, 0), o(3, 2, 0), o(5, 4, 0), o(7, 1, 2), o(9, 3, 2)),
        ChordShape.of(standard, x(1, 6, 0), o(3, 3, 1), o(5, 2, 0), o(7, 4, 1), o(9, 1, 2))
    )
    val rootlessDerivedFromDrop3Maj7 = listOf(
        ChordShape.of(standard, x(1, 1, 0), o(3, 3, 1), o(5, 2, 0), o(7, 4, 1), o(9, 6, 2)),
        ChordShape.of(standard, x(1, 4, 0), o(3, 6, 2), o(5, 3, 2), o(7, 2, 2), o(9, 4, 2)),
        ChordShape.of(standard, x(1, 2, 0), o(3, 4, 1), o(5, 6, 2), o(7, 3, 3), o(9, 2, 2)),
        ChordShape.of(standard, x(1, 3, 0), o(3, 2, 0), o(5, 4, 0), o(7, 6, 2), o(9, 3, 2)),
        ChordShape.of(standard, x(1, 5, 0), o(3, 2, 2), o(5, 1, 0), o(7, 3, 1), o(9, 5, 2)),
        ChordShape.of(standard, x(1, 3, 0), o(3, 5, 2), o(5, 2, 3), o(7, 1, 2), o(9, 3, 2)),
        ChordShape.of(standard, x(1, 4, 0), o(3, 3, -1), o(5, 5, 0), o(7, 2, 2), o(9, 1, 0)),
        ChordShape.of(standard, x(1, 2, 0), o(3, 1, -1), o(5, 3, -1), o(7, 5, 1), o(9, 2, 2)),
    )
    val rootlessDerivedFromDrop24Maj7 = listOf(
        ChordShape.of(standard, x(1, 4, 0), o(3, 3, -1), o(5, 5, 0), o(7, 2, 2), o(9, 6, 0)),
        ChordShape.of(standard, x(1, 2, 0), o(3, 6, -1), o(5, 3, -1), o(7, 5, 1), o(9, 2, 2)),
        ChordShape.of(standard, x(1, 5, 0), o(3, 2, 2), o(5, 6, 0), o(7, 3, 1), o(9, 5, 2)),
        ChordShape.of(standard, x(1, 1, 0), o(3, 5, -1), o(5, 2, 0), o(7, 6, -1), o(9, 3, -1)),
        ChordShape.of(standard, x(1, 3, 0), o(3, 2, 0), o(5, 4, 0), o(7, 1, 2), o(9, 5, 0)),
        ChordShape.of(standard, x(1, 1, 0), o(3, 5, -1), o(5, 2, 0), o(7, 4, 1), o(9, 1, 2)),
        ChordShape.of(standard, x(1, 4, 0), o(3, 1, 2), o(5, 5, 0), o(7, 2, 2), o(9, 4, 2)),
        ChordShape.of(standard, x(1, 5, 0), o(3, 4, -1), o(5, 1, 0), o(7, 5, -1), o(9, 2, 0))
    )
}

private fun o(degree: Int, string: Int, offset: Int): Pair<Int, FretPositionRelative> = degree to (string at offset)
private fun x(degree: Int, string: Int, offset: Int): Pair<Int, FretPositionRelative> =
    degree to (string at offset).copy(optional = true)

infix fun Int.at(offset: Int): FretPositionRelative = FretPositionRelative(this, offset)

@Serializable
data class FretPositionRelative(
    val string: Int,
    val offsetFromRoot: Int,
    val optional: Boolean = false,
)

@Serializable
data class ChordShape(
    val tuning: GuitarTuning,
    val positions: Map<Int, FretPositionRelative>
) {
    companion object {
        fun of(
            tuning: GuitarTuning,
            vararg pairs: Pair<Int, FretPositionRelative>
        ): ChordShape = ChordShape(tuning, mapOf(*pairs))
    }

    fun tab(rootNote: Note, overrideTuning: GuitarTuning = this.tuning, avoidOpenStrings: Boolean = false): List<Int> {
        val out = Array(tuning.size) { -1 }
        val offsets = this.tuning.mapIndexed { idx, it -> it - tuning[idx + 1] }
        val rootPosition = requireNotNull(positions[1]) { "Root note is not defined for the shape" }
        val rootNoteString = rootPosition.string
        val rootOffset = (rootNote - overrideTuning[rootNoteString] + offsets[rootNoteString - 1]).let {
            var i = it
            while (i !in 0..12 || avoidOpenStrings && i !in 1..12) {
                i = when {
                    i < 0 -> i + 12
                    avoidOpenStrings && i == 0 -> i + 12
                    i > 12 -> i - 12
                    else -> i
                }
            }
            i
        }
        if (!rootPosition.optional) {
            out[rootNoteString - 1] = rootOffset
        }

        var addOctave = false
        val playingStrings = mutableSetOf<Int>()
        positions.values.forEach { pos ->
            val noteString = pos.string
            playingStrings += noteString
            val notePos = rootOffset + pos.offsetFromRoot
            if (notePos < 0 || avoidOpenStrings && notePos == 0) addOctave = true
            if (!pos.optional) {
                out[noteString - 1] = notePos
            }
        }
        if (addOctave) {
            playingStrings.forEach { out[it - 1] += 12 }
        }
        return out.asList()
    }
}
