package guitar.assist.model

import kotlinx.serialization.Serializable


@Serializable
class GuitarTuning(private val notes: List<Note>) : List<Note> {

    companion object {
        val standard = GuitarTuning(listOf(E(4), B(3), G(3), D(3), A(2), E(2)))
    }

    override fun get(index: Int): Note {
        require(index in 1..6)
        return notes[index - 1]
    }

    override val size: Int
        get() = notes.size

    override fun contains(element: Note): Boolean {
        return notes.contains(element)
    }

    override fun containsAll(elements: Collection<Note>): Boolean {
        return notes.containsAll(elements)
    }

    override fun indexOf(element: Note): Int {
        return notes.indexOf(element)
    }

    override fun isEmpty(): Boolean {
        return notes.isEmpty()
    }

    override fun iterator(): Iterator<Note> {
        return notes.iterator()
    }

    override fun lastIndexOf(element: Note): Int {
        return notes.lastIndexOf(element)
    }

    override fun listIterator(): ListIterator<Note> {
        return notes.listIterator()
    }

    override fun listIterator(index: Int): ListIterator<Note> {
        return notes.listIterator(index)
    }

    override fun subList(fromIndex: Int, toIndex: Int): List<Note> {
        return notes.subList(fromIndex, toIndex)
    }

    override fun toString(): String {
        return "GuitarTuning[${notes.joinToString(separator = ",") { it.symbol }}]"
    }

    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class.js != other::class.js) return false

        other as GuitarTuning

        if (notes != other.notes) return false

        return true
    }

    override fun hashCode(): Int {
        return notes.hashCode()
    }
}