package guitar.assist

import guitar.assist.model.Project
import guitar.assist.model.jsonMapper
import kotlinx.browser.window
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString
import org.w3c.dom.get
import org.w3c.dom.set

object Storage {
    private val localStorage = window.localStorage

    fun getProject(id: String): Project = localStorage["project_$id"]
        ?.let {
            try {
                jsonMapper.decodeFromString<Project>(it)
            } catch (e: Exception) {
                null
            }
        }
        ?: Project.empty()

    fun storeProject(id: String, project: Project) {
        localStorage["project_$id"] = jsonMapper.encodeToString(project)
    }
}