package guitar.assist

import kotlinext.js.jso
import kotlinx.browser.document
import kotlinx.css.Color
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.useEffect
import svgdotjs.*
import kotlin.math.ceil
import kotlin.math.min

external interface ChordBoxProps : Props {
    var id: String
    var chordDescriptor: ChordDescriptor

    var width: Int
    var height: Int
    var numStrings: Int
    var numFrets: Int

    var x: Int?
    var y: Int?

    var defaultColor: Color?
    var bgColor: Color?
    val labelColor: Color?
    var bridgeColor: Color?
    var stringColor: Color?
    var fretColor: Color?
    var strokeColor: Color?
    var moreStrokeColor: Color?
    var textColor: Color?

    var strokeWidth: Int?
    var stringWidth: Int?
    var fretWidth: Int?
    var labelWeight: String?

    var fontSize: Double?
    var circleRadius: Double?
}

val ChordBox = FC<ChordBoxProps> { props ->
    val id = props.id
    val chordDescriptor = props.chordDescriptor
    val numStrings = props.numStrings;
    val numFrets = props.numFrets;
    val width = (props.width * 0.9).toInt()
    val height = (props.height * 0.9).toInt()

    val defaultColor = props.defaultColor ?: Color.darkGrey
    val bgColor = props.bgColor ?: Color.white
    val labelColor = props.labelColor ?: Color.white
    val bridgeColor = props.bridgeColor ?: defaultColor
    val stringColor = props.stringColor ?: defaultColor
    val fretColor = props.fretColor ?: defaultColor
    val strokeColor = props.strokeColor ?: defaultColor
    val moreStrokeColor = props.moreStrokeColor ?: defaultColor
    val textColor = props.textColor ?: defaultColor
    val strokeWidth = props.strokeWidth ?: 1
    val stringWidth = props.stringWidth ?: strokeWidth
    val fretWidth = props.fretWidth ?: strokeWidth
    val labelWeight = props.labelWeight ?: "100"
    val fontFamily = "-apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, Helvetica, Arial, sans-serif, " +
            "\"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\""
    val fontStyle = "light"
    val fontWeight = "100"

    // Initialize scaled-spacing
    val spacing = width / numStrings;
    var fretSpacing = height / (numFrets + 1);

    val canvas = SVG()

    // Add room on sides for finger positions on 1. and 6. string
    val x = (props.x ?: 0) + width * 0.1 + spacing / 2.0
    val y = (props.y ?: 0) + height * 0.05 + fretSpacing

    val circleRadius = props.circleRadius ?: (width / 20.0)
    val barreRadius = width / 25
    val fontSize = props.fontSize ?: ceil(width / 8.0)
    val barShiftX = width / 28
    val bridgeStrokeWidth = ceil(height / 36.0).toInt()

    fun drawText(x: Double, y: Double, msg: String, fontData: FontData? = null, color: Color = textColor): Text {

        val text = canvas.text(msg)
            .stroke(jso {
                this.color = color
            })
            .fill(jso {
                this.color = color
            })
            .font(fontData ?: jso {
                this.size = fontSize
                this.family = fontFamily
                this.weight = fontWeight
                this.style = fontStyle
            })

        return text.move(x - text.length() / 2.0, y)
    }

    fun drawLine(x: Double, y: Double, newX: Double, newY: Double): Line {
        return canvas.line(0.0, 0.0, newX - x, newY - y).move(x, y);
    }

    fun lightUp(string: Int, fret: Int, label: String?, position: Int, positionText: Int, color: Color) {
        val stringNum = numStrings - string
        val shiftPosition = if (position == 1 && positionText == 1) positionText else 0

        val mute = fret == -1
        val fretNum = if (fret == -1) 0 else fret - shiftPosition

        val _x = x + spacing * stringNum;
        var _y = y + fretSpacing * fretNum;

        if (fretNum == 0) {
            _y -= bridgeStrokeWidth
        }

        if (!mute) {
            canvas
                .circle()
                .move(_x, _y - fretSpacing / 2.0)
                .radius(circleRadius)
                .stroke(jso {
                    this.color = color
                    this.width = strokeWidth.toDouble()
                })
                .fill(jso {
                    this.color = color
                })
        } else {
            drawText(_x, _y - fretSpacing, "X", color = color)
        }

        if (label != null) {
            val labelFontSize = fontSize * 0.55
            val textYShift = labelFontSize * 0.66
            drawText(_x, _y - fretSpacing / 2 - textYShift, label, jso {
                this.family = fontFamily
                weight = labelWeight
                size = labelFontSize
            })
                .stroke(jso {
                    this.width = 0.7
                    this.color = if (fretNum != 0) labelColor else strokeColor
                })
                .fill(jso {
                    this.color = if (fretNum != 0) labelColor else strokeColor
                });
        }
    }

    fun lightBar(stringFrom: Int, stringTo: Int, theFretNum: Int, position: Int, positionText: Int) {
        var fretNum = theFretNum
        if (position == 1 && positionText == 1) {
            fretNum -= positionText
        }

        val stringFromNum = numStrings - stringFrom
        val stringToNum = numStrings - stringTo

        val _x = x + spacing * stringFromNum - barShiftX
        val xTo = x + spacing * stringToNum + barShiftX

        val _y = y + fretSpacing * (fretNum - 1) + fretSpacing / 4.0
        val yTo = y + fretSpacing * (fretNum - 1) + (fretSpacing / 4) * 3.0

        canvas
            .rect(xTo - _x, yTo - _y)
            .move(_x, _y)
            .radius(barreRadius)
            .fill(jso {color = strokeColor})
    }

    fun draw(descriptor: ChordDescriptor) {
        val chord = descriptor.chord
        val position = descriptor.position
        val positionText = descriptor.positionText
        val barres = descriptor.barres
        val tuning = descriptor.tuning
        val moreNotes = descriptor.moreNotes

        if (tuning.isEmpty()) {
            fretSpacing = height / (numFrets + 1);
        }

        // Draw guitar bridge
        if (position <= 1) {
            val fromX = x;
            val fromY = y - bridgeStrokeWidth;
            canvas
                .rect(x + spacing * (numStrings - 1) - fromX, y - fromY)
                .move(fromX, fromY)
                .stroke(jso { this.width = 0.0 })
                .fill(jso { color = bridgeColor })
        } else {
            // Draw position number
            drawText(
                x - spacing / 2 - spacing * 0.3,
                y + fretSpacing * positionText,
                position.toString()
            );
        }

        // Draw strings
        for (i in 0 until numStrings) {
            drawLine(x + spacing * i, y, x + spacing * i, y + fretSpacing * numFrets)
                .stroke(jso {
                    this.width = stringWidth.toDouble()
                    color = stringColor
                })
        }

        // Draw frets
        for (i in 0 until numFrets + 1) {
            drawLine(x, y + fretSpacing * i, x + spacing * (numStrings - 1), y + fretSpacing * i)
                .stroke(jso {
                    this.width = fretWidth.toDouble()
                    color = fretColor
                })
        }

        // Draw tuning keys
        if (/*showTuning && */tuning.isNotEmpty()) {
            tuning.reversed().forEachIndexed { i, tune ->
                drawText(
                    x + spacing * i,
                    y + numFrets * fretSpacing + fretSpacing / 12,
                    tune.symbol
                );
            }
        }

        // Draw notes
        moreNotes.forEach { p ->
            lightUp(
                p.string,
                p.fret,
                p.label,
                position,
                positionText,
                moreStrokeColor
            )
        }
        chord.forEach { p ->
            lightUp(
                p.string,
                p.fret,
                p.label,
                position,
                positionText,
                strokeColor
            )
        }

        // Draw barres
        barres.forEach { barre ->
            lightBar(
                barre.strings.first,
                barre.strings.last,
                barre.fret,
                position,
                positionText
            )
        }
    }

    useEffect {
        val root = document.getElementById(id)!!
        while (root.lastElementChild != null) {
            root.removeChild(root.lastElementChild!!)
        }

        canvas.addTo("#$id")
        canvas.size(props.width, props.height)

        draw(chordDescriptor)
    }

    div {
        this.id = id
    }
}