package guitar.assist

import guitar.assist.model.Chord
import guitar.assist.model.ChordShape
import guitar.assist.model.GuitarTuning
import guitar.assist.model.plus
import kotlinx.css.Color
import react.FC
import react.Props
import react.memo
import react.useState
import kotlin.math.absoluteValue
import kotlin.math.max
import kotlin.random.Random

external interface ChordShapeProps : Props {
    var chordShape: ChordShape
    var chord: Chord
    var tuning: GuitarTuning?
    var id: String?
    var width: Int?
    var singleFretHeight: Int?
}

val ChordShapeBox = memo(FC<ChordShapeProps> { props ->
    val tuning = props.tuning ?: GuitarTuning.standard
    val chordShape = props.chordShape
    val chord = props.chord
    val id by useState { props.id ?: "chordbox_${Random.nextLong().absoluteValue.toString(36)}" }

    val tab = chordShape.tab(chord.rootNote, tuning, avoidOpenStrings = true)
    val minFret = max(tab.filter { it >= 0 }.minOrNull()?.minus(4) ?: 0, 0)
    val chordMinFret = tab.filter { it >= 0 }.minOrNull() ?: 1
    val frets = 10
    val scaleNotes = chord.scaleNotes().map { it.symbol }.toSet()

    val chordDescriptor = ChordDescriptor(
        chord = tab.mapIndexed { idx, fret ->
            val string = idx + 1
            TabNote(
                string,
                fret.takeIf { it >= 0 }?.let { it - minFret + 1 } ?: -1,
                fret.takeIf { it >= 0 }?.let { (tuning[string] + it).symbol }
            )
        },
        moreNotes = (max(minFret, 1) until minFret + frets).asSequence()
            .map { it to (1..tuning.size) }
            .flatMap { fretToStrings ->
                fretToStrings.second.asSequence()
                    .map { string -> string to fretToStrings.first }
            }
            .mapNotNull { (string, fret) ->
                val note = tuning[string] + fret
                if (note.symbol in scaleNotes) {
                    TabNote(
                        string,
                        fret - minFret + 1,
                        note.symbol
                    )
                } else {
                    null
                }
            }
            .toList(),
        tuning = tuning,
        position = chordMinFret,
        positionText = max(chordMinFret - minFret, 1)
    )

    ChordBox {
        this.id = id
        this.chordDescriptor = chordDescriptor
        width = props.width ?: 130
        height = frets * (props.singleFretHeight ?: 25)
        numStrings = tuning.size
        numFrets = frets

        strokeColor = Color.darkRed
        moreStrokeColor = Color.lightGrey

        circleRadius = 7.0
        fontSize = 18.0
    }
}) { a, b -> a.chordShape == b.chordShape && a.chord == b.chord }
