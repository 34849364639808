package guitar.assist

import csstype.Border
import csstype.Color
import csstype.LineStyle
import csstype.px
import guitar.assist.model.Chord
import guitar.assist.model.ChordSequence
import guitar.assist.model.ChordShape
import guitar.assist.model.GuitarTuning.Companion.standard
import guitar.assist.model.at
import guitar.assist.model.shapes
import kotlinext.js.jso
import mui.icons.material.ArrowBack
import mui.icons.material.ArrowCircleLeftOutlined
import mui.icons.material.ArrowCircleRightOutlined
import mui.icons.material.ArrowForward
import mui.icons.material.DeleteForever
import mui.material.Box
import mui.material.Dialog
import mui.material.DialogActions
import mui.material.DialogContent
import mui.material.DialogScroll
import mui.material.DialogTitle
import mui.material.IconButton
import mui.material.Stack
import mui.material.StackDirection
import mui.material.Typography
import mui.system.ResponsiveStyleValue
import react.FC
import react.Props
import react.useState
import kotlin.math.max
import kotlin.math.min

external interface ChordSequenceProps : Props {
    var chordSequence: ChordSequence
    var onShapeChange: ((Int, Chord, ChordShape) -> Unit)?
    var onChordDelete: ((Int, Chord, ChordShape?) -> Unit)?
    var onChordOrderChange: ((Int, Int) -> Unit)?
}

data class ActiveChord(
    val idx: Int,
    val chord: Chord,
    val shape: ChordShape?
)

val ChordSequence = FC<ChordSequenceProps> { props ->
    val chordSequence = props.chordSequence
    val onShapeChange = props.onShapeChange ?: { _, _, _ -> }
    val onChordDelete = props.onChordDelete ?: { _, _, _ -> }
    val onChordOrderChange = props.onChordOrderChange ?: { _, _ -> }
    var activeChordIdx by useState { -1 }
    var activeChord by useState<ActiveChord>()

    var shapeIndexes by useState {
        chordSequence.chords
            .mapIndexedNotNull { idx, (chord, chordShape) ->
                chordShape
                    ?.let { shape -> chord.shapes().indexOfFirst { it == shape } }
                    ?.takeIf { it >= 0 }
                    ?.let { idx to it }
            }
            .toMap()
    }

    fun swapChords(idx: Int, newIdx: Int) {
        val a = shapeIndexes[idx]
        val b = shapeIndexes[newIdx]
        shapeIndexes = shapeIndexes.entries.mapIndexedNotNull { i, (k, v) ->
            val vv = when (i) {
                idx -> b
                newIdx -> a
                else -> v
            }
            vv?.let { k to it }
        }.toMap()
        activeChordIdx = newIdx
        onChordOrderChange(idx, newIdx)
    }

    Box {
        chordSequence.chords.mapIndexed { idx, (theChord, shape) ->
            Box {
                sx = jso {
                    float = csstype.Float.left
                    margin = 5.px
                    padding = 5.px
                    textAlign = csstype.TextAlign.center
                    asDynamic().flex = 1
                    border = if (activeChordIdx == idx) {
                        Border(1.px, LineStyle.dotted, Color("darkRed"))
                    } else {
                        Border(1.px, LineStyle.dotted, Color("transparent"))
                    }
                }
                val shapes = theChord.shapes()

                Stack {
                    direction = ResponsiveStyleValue(StackDirection.row)
                    asDynamic().justifyContent = "space-evenly"

                    if (activeChordIdx == idx) {
                        IconButton {
                            ArrowBack { }
                            onClick = { e ->
                                swapChords(idx, max(idx - 1, 0))
                            }
                        }
                    }
                    Typography {
                        paragraph = true
                        +(theChord.toString() + if (shapes.isEmpty()) "*" else "")
                    }
                    if (activeChordIdx == idx) {
                        IconButton {
                            ArrowForward { }
                            onClick = { e ->
                                swapChords(idx, min(idx + 1, chordSequence.chords.size - 1))
                            }
                        }
                    }
                }

                Box {
                    onClick = { e ->
                        activeChordIdx = (if (activeChordIdx == idx) -1 else idx)
                    }
                    ContextMenuHandler {
                        activeChord = ActiveChord(idx, theChord, shape)
                    }.bind(this)
                    onContextMenu = { e ->
                        activeChord = ActiveChord(idx, theChord, shape)
                        e.preventDefault()
                    }
                    if (shapes.isNotEmpty()) {
                        ChordShapeBox {
                            chord = theChord
                            chordShape = shapeIndexes[idx]
                                ?.let { shapes[it] }
                                ?: shape
                                        ?: shapes[0]
                        }
                    } else {
                        ChordShapeBox {
                            chord = theChord
                            chordShape = ChordShape.of(standard, 1 to (6 at 0))
                        }
                    }
                }
            }
        }

        if (activeChord != null) {
            val ac = activeChord!!
            val acShapes = ac.chord.shapes()
            Dialog {
                open = true
                scroll = DialogScroll.paper
                onClose = { _, _ -> activeChord = null }
                DialogTitle {
                    +"Edit: ${ac.chord}"
                }
                DialogContent {
                    if (acShapes.isNotEmpty()) {
                        ChordShapeBox {
                            width = 200
                            singleFretHeight = 30
                            chord = ac.chord
                            chordShape = shapeIndexes[ac.idx]
                                ?.let { acShapes[it] }
                                ?: ac.shape
                                        ?: acShapes[0]
                        }
                    } else {
                        ChordShapeBox {
                            width = 200
                            singleFretHeight = 30
                            chord = ac.chord
                            chordShape = ChordShape.of(standard, 1 to (6 at 0))
                        }
                    }
                }
                DialogActions {
                    if (acShapes.isNotEmpty()) {
                        IconButton {
                            ArrowCircleLeftOutlined {}
                            onClick = {
                                val newShapeIndexes = HashMap(shapeIndexes).also {
                                    val shapeIndex = it[ac.idx] ?: 0
                                    it[ac.idx] = max(0, shapeIndex - 1)
                                }
                                val shapeIndex = newShapeIndexes.getValue(ac.idx)
                                onShapeChange(ac.idx, ac.chord, acShapes[shapeIndex])

                                shapeIndexes = newShapeIndexes
                            }
                        }
                        IconButton {
                            ArrowCircleRightOutlined {}
                            onClick = {
                                val newShapeIndexes = HashMap(shapeIndexes).also {
                                    val shapeIndex = it[ac.idx] ?: 0
                                    it[ac.idx] = min(shapeIndex + 1, acShapes.size - 1)
                                }
                                val shapeIndex = newShapeIndexes.getValue(ac.idx)
                                shapeIndexes = newShapeIndexes
                                onShapeChange(ac.idx, ac.chord, acShapes[shapeIndex])
                            }
                        }
                    }
                    IconButton {
                        DeleteForever {}
                        onClick = {
                            onChordDelete(ac.idx, ac.chord, ac.shape)
                            activeChord = null
                        }
                    }
                }
            }
        }
    }
}