package guitar.assist.model

import kotlinx.serialization.Serializable

@Serializable
abstract class Scale {

    abstract fun intervals(): Array<Int>

    fun get(key: Note, degree: Int, startingIndex: Int = 0): Note {
        require(degree >= 1)
        val intervals = intervals()
        var interval = startingIndex
        (0 until degree - 1).forEach {
            val i = it % intervals.size
            interval += intervals[i]
        }
        return key + interval
    }

    fun of(key: Note, vararg degree: Int): List<Note> {
        return degree.map { get(key, it) }
    }

    fun mode(offset: Int): Scale {
        val a = this
        return object : Scale() {
            override fun intervals(): Array<Int> {
                val protoIntervals = a.intervals()
                val newIntervals = Array(protoIntervals.size) {
                    protoIntervals[(offset + it) % protoIntervals.size]
                }
                return newIntervals
            }
        }
    }
}

object Major : Scale() {
    override fun intervals(): Array<Int> = arrayOf(2, 2, 1, 2, 2, 2, 1)
}

@Serializable
object Ionian : Scale() {
    override fun intervals(): Array<Int> = Major.mode(0).intervals()
}

@Serializable
object Dorian : Scale() {
    override fun intervals(): Array<Int> = Major.mode(1).intervals()
}

@Serializable
object Phrygian : Scale() {
    override fun intervals(): Array<Int> = Major.mode(2).intervals()
}

@Serializable
object Lydian : Scale() {
    override fun intervals(): Array<Int> = Major.mode(3).intervals()
}

@Serializable
object Mixolydian : Scale() {
    override fun intervals(): Array<Int> = Major.mode(4).intervals()
}

@Serializable
object Aeolian : Scale() {
    override fun intervals(): Array<Int> = Major.mode(5).intervals()
}

@Serializable
object Locrian : Scale() {
    override fun intervals(): Array<Int> = Major.mode(6).intervals()
}

@Serializable
object HarmonicMinor : Scale() {
    override fun intervals(): Array<Int> = arrayOf(2, 1, 2, 2, 1, 3, 1)
}

@Serializable
object MelodicMinor : Scale() {
    override fun intervals(): Array<Int> = arrayOf(2, 1, 2, 2, 2, 2, 1)
}

@Serializable
object LydianAugmented : Scale() {
    override fun intervals(): Array<Int> = MelodicMinor.mode(2).intervals()
}

@Serializable
object AugmentedHexatonic : Scale() {
    override fun intervals(): Array<Int> = arrayOf(3, 1, 3, 1, 3, 1)
}
