package guitar.assist

import guitar.assist.model.GuitarTuning

data class ChordDescriptor(
    val chord: List<TabNote>,
    val moreNotes: List<TabNote> = emptyList(),
    val position: Int = 0,
    val barres: List<Barre> = emptyList(),
    val positionText: Int = 0,
    val tuning: GuitarTuning = GuitarTuning.standard,
)

data class TabNote(
    val string: Int,
    val fret: Int,
    val label: String? = null
)

data class Barre(
    val fret: Int,
    val strings: IntRange
)
