package guitar.assist

import kotlinx.browser.document
import mui.material.Box
import react.RBuilder
import react.create
import react.dom.render
import react.router.Route
import react.router.Routes
import react.router.dom.BrowserRouter

fun main() {
    val root = requireNotNull(document.getElementById("root")) {
        "The root element with id=`root` is not found"
    }
    render(root) {
        app()
    }
}

fun RBuilder.app() = BrowserRouter {
    Routes {
        Route {
            attrs.path = "/"
            attrs.element = Box.create {
                GuitarAssist {}
            }
        }
    }
}
