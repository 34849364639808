package guitar.assist

import csstype.AlignItems
import csstype.Display
import csstype.Margin
import csstype.OverflowX
import csstype.pct
import csstype.px
import guitar.assist.model.*
import kotlinext.js.jso
import mui.icons.material.Add
import mui.icons.material.AddCircle
import mui.material.Autocomplete
import mui.material.AutocompleteProps
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Divider
import mui.material.IconButton
import mui.material.MenuItem
import mui.material.Stack
import mui.material.StackDirection
import mui.material.TextField
import mui.material.Typography
import mui.system.ResponsiveStyleValue
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLSelectElement
import react.FC
import react.Props
import react.create
import react.createElement
import react.dom.onChange
import react.key
import react.useEffect
import react.useState

val GuitarAssist = FC<Props> {
    val projectId = "my"

    var project by useState { Storage.getProject(projectId) }

    var chordSequences: List<ChordSequence> by useState { project.chordSequences }

    var newProgressionRootNote by useState<Note> { C(3) }
    var newProgressionDegreesAsString by useState { "2 5 1" }
    var newProgressionChordDegreesAsString by useState { "1 3 5 7" }
    var newChordSequence by useState<ChordSequence>()

    val allChords by useState {
        (0..11).asSequence()
            .map { C(3) + it }
            .flatMap { rootNote -> chordBuilders.asSequence().map { builder -> builder(rootNote) } }
            .map { it.toString() to it }
            .toMap()
    }

    useEffect(chordSequences) {
        val newProject = project.copy(chordSequences = chordSequences)
        Storage.storeProject(projectId, newProject)

        project = newProject
    }

    Stack {
        chordSequences.mapIndexed { idx, chordSequence ->
            Stack {
                sx = jso {
                    margin = Margin("20px")
                }
                asDynamic().alignItems = "flex-start"
                Box {
                    sx = jso {
                        width = 100.pct
                        overflowX = OverflowX.auto
                        display = Display.flex
                        alignItems = AlignItems.flexStart
                    }
                    ChordSequence {
                        this.chordSequence = chordSequence
                        onShapeChange = { chordIdx, chord, shape ->
                            chordSequences = chordSequences.mapIndexed { i, c ->
                                if (i == idx) {
                                    chordSequence.replace(
                                        chordIdx,
                                        chord to shape
                                    )
                                } else {
                                    c
                                }
                            }
                        }
                        onChordDelete = { chordIdx, chord, shape ->
                            chordSequences = chordSequences.mapIndexedNotNull { i, c ->
                                if (i == idx) {
                                    chordSequence.remove(chordIdx).takeIf { it.chords.isNotEmpty() }
                                } else {
                                    c
                                }
                            }
                        }
                        onChordOrderChange = {chordIdx, newChordIdx ->
                            chordSequences = chordSequences.mapIndexedNotNull { i, c ->
                                if (i == idx) {
                                    chordSequence.swap(chordIdx,newChordIdx)
                                } else {
                                    c
                                }
                            }
                        }
                    }
                }
                Stack {
                    direction = ResponsiveStyleValue(StackDirection.row)
                    @Suppress("UPPER_BOUND_VIOLATED")
                    Autocomplete<AutocompleteProps<ChordOption>> {
                        sx = jso {
                            width = 200.px
                        }
                        options = allChords.values.map { chordOption(it) }.toTypedArray()
                        onChange = { _, value, _, _ ->
                            newChordSequence = value.unsafeCast<ChordOption?>()?.label
                                ?.let { allChords.getValue(it) }
                                ?.let { chordSequence + it }
                                ?: chordSequence
                        }
                        isOptionEqualToValue = { o1, o2 -> o1.label == o2.label }
                        renderInput = { params ->
                            TextField.create {
                                +params
                                label = Box.create { +"Append a new chord" }
                            }
                        }
                    }
                    IconButton {
                        onClick = {
                            newChordSequence?.also {
                                chordSequences = chordSequences.mapIndexed { i, c -> if (i == idx) it else c }
                            }
                        }
                        AddCircle {}
                    }
                }
            }
            Divider {}
        }
        if (chordSequences.isEmpty()) {
            Stack {
                direction = ResponsiveStyleValue(StackDirection.column)
                spacing = ResponsiveStyleValue("10px")
                sx = jso {
                    margin = Margin("20px")
                    maxWidth = 500.px
                }
                Typography {
                    variant = "h5"
                    paragraph = true
                    +"Create chord progression"
                }
                Stack {
                    direction = ResponsiveStyleValue(StackDirection.row)
                    spacing = ResponsiveStyleValue("10px")
                    TextField {
                        select = true
                        value = newProgressionRootNote.symbol
                        label = createElement { +"Root note" }
                        sx = jso {
                            minWidth = 80.px
                        }
                        onChange = { e ->
                            newProgressionRootNote =
                                Note.all0.first { it.symbol == e.target.unsafeCast<HTMLSelectElement>().value }
                        }
                        Note.all0.map {
                            MenuItem {
                                key = it.symbol
                                value = it.symbol
                                +it.symbol
                            }
                        }
                    }
                    TextField {
                        fullWidth = true
                        value = newProgressionDegreesAsString
                        label = createElement { +"Progression degress" }


                        onChange = { e ->
                            val v = e.target.unsafeCast<HTMLInputElement>().value
                            newProgressionDegreesAsString = v.replace("[^0-9 ]".toRegex(), "")
                        }
                    }
                    TextField {
                        fullWidth = true
                        value = newProgressionChordDegreesAsString
                        label = createElement { +"Chord degrees" }


                        onChange = { e ->
                            val v = e.target.unsafeCast<HTMLInputElement>().value
                            newProgressionChordDegreesAsString = v.replace("[^0-9 ]".toRegex(), "")
                        }
                    }
                }
                Button {
                    +"Create progression"
                    variant = ButtonVariant.contained
                    startIcon = Add.create {}
                    onClick = {
                        val progression = newProgressionRootNote.progression(
                            newProgressionDegreesAsString.trim()
                                .split("\\s+".toRegex())
                                .map { it.toInt() }
                                .toTypedArray(),
                            newProgressionChordDegreesAsString.trim()
                                .split("\\s+".toRegex())
                                .map { it.toInt() }
                                .toTypedArray(),
                            Ionian
                        ).map { it to it.shapes().firstOrNull() }
                        val newSeq = arrayListOf<ChordSequence>()
                        newSeq.addAll(chordSequences)
                        newSeq.add(ChordSequence(progression))
                        chordSequences = newSeq
                    }
                }
            }
        }
    }
}


private external interface ChordOption {
    var label: String
}

private fun chordOption(chord: Chord): ChordOption = jso {
    label = chord.toString()
}