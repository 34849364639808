package guitar.assist.model

import kotlinx.serialization.Serializable

@Serializable
abstract class Note {

    companion object {
        val all0 = listOf(
            C(0),
            Cs(0),
            Db(0),
            D(0),
            Ds(0),
            Eb(0),
            E(0),
            F(0),
            Fs(0),
            Gb(0),
            G(0),
            Gs(0),
            Ab(0),
            A(0),
            As(0),
            Bb(0),
            B(0)
        )
    }

    abstract val octave: Int
    abstract val symbol: String
    abstract val semitonesFromC: Int

    operator fun inc(): Note = addSemitone()
    abstract fun addSemitone(): Note
    abstract fun subSemitone(): Note

    override fun toString(): String = "$symbol($octave)"

    operator fun minus(note: Note): Int {
        val octaveDiff = 12 * (this.octave - note.octave)
        val innerDiff = this.semitonesFromC - note.semitonesFromC
        return octaveDiff + innerDiff
    }

    override operator fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null) return false

        other as Note

        if (octave != other.octave) return false
        if (semitonesFromC != other.semitonesFromC) return false

        return true
    }

    override fun hashCode(): Int {
        var result = octave
        result = 31 * result + semitonesFromC
        return result
    }

}

@Serializable
class C(override val octave: Int) : Note() {
    override fun addSemitone(): Note = Cs(octave)
    override fun subSemitone(): Note = B(octave - 1)
    override val symbol: String = "C"
    override val semitonesFromC: Int = 0
}

@Serializable
class Cs(override val octave: Int) : Note() {
    override fun addSemitone(): Note = D(octave)
    override fun subSemitone(): Note = C(octave)
    override val symbol: String = "C#"
    override val semitonesFromC: Int = 1
}

@Serializable
class Db(override val octave: Int) : Note() {
    override fun addSemitone(): Note = D(octave)
    override fun subSemitone(): Note = C(octave)
    override val symbol: String = "Db"
    override val semitonesFromC: Int = 1

}

@Serializable
class D(override val octave: Int) : Note() {
    override fun addSemitone(): Note = Ds(octave)
    override fun subSemitone(): Note = Cs(octave)
    override val symbol: String = "D"
    override val semitonesFromC: Int = 2
}

@Serializable
class Ds(override val octave: Int) : Note() {
    override fun addSemitone(): Note = E(octave)
    override fun subSemitone(): Note = D(octave)
    override val symbol: String = "D#"
    override val semitonesFromC: Int = 3
}

@Serializable
class Eb(override val octave: Int) : Note() {
    override fun addSemitone(): Note = E(octave)
    override fun subSemitone(): Note = D(octave)
    override val symbol: String = "Eb"
    override val semitonesFromC: Int = 3
}

@Serializable
class E(override val octave: Int) : Note() {
    override fun addSemitone(): Note = F(octave)
    override fun subSemitone(): Note = Eb(octave)
    override val symbol: String = "E"
    override val semitonesFromC: Int = 4
}

@Serializable
class F(override val octave: Int) : Note() {
    override fun addSemitone(): Note = Fs(octave)
    override fun subSemitone(): Note = E(octave)
    override val symbol: String = "F"
    override val semitonesFromC: Int = 5
}

@Serializable
class Fs(override val octave: Int) : Note() {
    override fun addSemitone(): Note = G(octave)
    override fun subSemitone(): Note = F(octave)
    override val symbol: String = "F#"
    override val semitonesFromC: Int = 6
}

@Serializable
class Gb(override val octave: Int) : Note() {
    override fun addSemitone(): Note = G(octave)
    override fun subSemitone(): Note = F(octave)
    override val symbol: String = "Gb"
    override val semitonesFromC: Int = 6
}

@Serializable
class G(override val octave: Int) : Note() {
    override fun addSemitone(): Note = Gs(octave)
    override fun subSemitone(): Note = Gb(octave)
    override val symbol: String = "G"
    override val semitonesFromC: Int = 7
}

@Serializable
class Gs(override val octave: Int) : Note() {
    override fun addSemitone(): Note = A(octave)
    override fun subSemitone(): Note = G(octave)
    override val symbol: String = "G#"
    override val semitonesFromC: Int = 8
}

@Serializable
class Ab(override val octave: Int) : Note() {
    override fun addSemitone(): Note = A(octave)
    override fun subSemitone(): Note = G(octave)
    override val symbol: String = "Ab"
    override val semitonesFromC: Int = 8
}

@Serializable
class A(override val octave: Int) : Note() {
    override fun addSemitone(): Note = As(octave)
    override fun subSemitone(): Note = Ab(octave)
    override val symbol: String = "A"
    override val semitonesFromC: Int = 9
}

@Serializable
class As(override val octave: Int) : Note() {
    override fun addSemitone(): Note = B(octave)
    override fun subSemitone(): Note = A(octave)
    override val symbol: String = "A#"
    override val semitonesFromC: Int = 10
}

@Serializable
class Bb(override val octave: Int) : Note() {
    override fun addSemitone(): Note = B(octave)
    override fun subSemitone(): Note = A(octave)
    override val symbol: String = "Bb"
    override val semitonesFromC: Int = 10
}

@Serializable
class B(override val octave: Int) : Note() {
    override fun addSemitone(): Note = C(octave + 1)
    override fun subSemitone(): Note = Bb(octave)
    override val symbol: String = "B"
    override val semitonesFromC: Int = 11
}

operator fun Note.plus(semitones: Int): Note {
    require(semitones >= 0) { "semitones >= 0 but $semitones found"}
    var r = this
    repeat(semitones) {
        r = r.addSemitone()
    }
    return r
}
