package guitar.assist.model

import kotlinx.serialization.json.Json
import kotlinx.serialization.modules.SerializersModule
import kotlinx.serialization.modules.polymorphic


val Models = SerializersModule {
    polymorphic(Note::class) {
        subclass(C::class, C.serializer())
        subclass(Cs::class, Cs.serializer())
        subclass(Db::class, Db.serializer())
        subclass(D::class, D.serializer())
        subclass(Ds::class, Ds.serializer())
        subclass(Eb::class, Eb.serializer())
        subclass(E::class, E.serializer())
        subclass(F::class, F.serializer())
        subclass(Fs::class, Fs.serializer())
        subclass(Gb::class, Gb.serializer())
        subclass(G::class, G.serializer())
        subclass(Gs::class, Gs.serializer())
        subclass(Ab::class, Ab.serializer())
        subclass(A::class, A.serializer())
        subclass(As::class, As.serializer())
        subclass(Bb::class, Bb.serializer())
        subclass(B::class, B.serializer())
    }
    polymorphic(Scale::class) {
        subclass(Ionian::class, Ionian.serializer())
        subclass(Dorian::class, Dorian.serializer())
        subclass(Phrygian::class, Phrygian.serializer())
        subclass(Lydian::class, Lydian.serializer())
        subclass(Mixolydian::class, Mixolydian.serializer())
        subclass(Aeolian::class, Aeolian.serializer())
        subclass(Locrian::class, Locrian.serializer())
        subclass(HarmonicMinor::class, HarmonicMinor.serializer())
        subclass(MelodicMinor::class, MelodicMinor.serializer())
        subclass(LydianAugmented::class, LydianAugmented.serializer())
    }
}

val jsonMapper = Json {
    serializersModule = Models
}