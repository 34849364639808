package guitar.assist

import kotlinx.browser.window
import org.w3c.dom.Touch
import org.w3c.dom.get
import react.dom.DOMAttributes

class ContextMenuHandler(
    val callback: (Touch?) -> Unit
) {
    private val longPressDuration = 610
    private var contextMenuPossible = false
    private var longPressCountdown: Int? = null

    fun bind(e: DOMAttributes<*>) {
        e.onTouchStart = { e ->
            contextMenuPossible = true
            val touch = e.touches[0]

            longPressCountdown = window.setTimeout({
                contextMenuPossible = false
                callback(touch)
            }, longPressDuration)
        }
        e.onTouchMove = { _ ->
            longPressCountdown?.let { window.clearTimeout(it) }
        }
        e.onTouchCancel = {
            contextMenuPossible = false
            longPressCountdown?.let { window.clearTimeout(it) }
        }
        e.onTouchEnd = {
            contextMenuPossible = false
            longPressCountdown?.let { window.clearTimeout(it) }
        }
    }
}